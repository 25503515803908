<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Perfiles
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar todos los perfiles creados.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <!-- begin::Filters -->
        <v-card class="mb-4">
          <div class="text-h6 font-weight-medium px-4 pt-4">Filtros</div>
          <v-divider></v-divider>
          <div class="row align-items-center mx-4 my-4">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-12">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="evaluations.search"
                  ></v-text-field>
                </div>
              </div>
              <div class="row align-items-center mb-5">
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="grades.isLoading ? 'Cargando grados' : 'Grado'"
                      :loading="grades.isLoading"
                      :disabled="!grades.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="grades.data"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.grade_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ evaluations.filters.grade_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        specialities.isLoading
                          ? 'Cargando especialidades'
                          : 'Especialidad'
                      "
                      :loading="specialities.isLoading"
                      :disabled="!specialities.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="specialities.data"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.speciality_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ evaluations.filters.speciality_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        evaluationStatuses.isLoading
                          ? 'Cargando estados del perfil'
                          : 'Estado del perfil'
                      "
                      :loading="evaluationStatuses.isLoading"
                      :disabled="!evaluationStatuses.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="evaluationStatuses.data"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.evaluation_status_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            evaluations.filters.evaluation_status_id.length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        activityCategories.isLoading
                          ? 'Cargando agrupaciones de los perfiles'
                          : 'Agrupación del perfil'
                      "
                      :loading="activityCategories.isLoading"
                      :disabled="!activityCategories.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="activityCategories.data"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.activity_category_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            evaluations.filters.activity_category_id.length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-autocomplete
                      :label="
                        subjects.isLoading
                          ? 'Cargando asignaturas'
                          : 'Asignaturas'
                      "
                      :loading="subjects.isLoading"
                      :disabled="!subjects.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="subjects.data"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.subject_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ evaluations.filters.subject_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle class="mt-1">
                            <span>
                              Grado
                              <strong class="ml-1">{{
                                data.item.grade
                              }}</strong>
                            </span>
                            <span class="ml-2">
                              Especialidad
                              <strong class="ml-1">{{
                                data.item.speciality
                              }}</strong>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <!-- Aqui se puso de acorde al evaluations porque no habia forma de saber si los docentes estaban cargando -->
                    <v-autocomplete
                      :label="
                        evaluations.isLoading
                          ? 'Cargando docentes e instructores'
                          : 'Docente o instructor'
                      "
                      :loading="evaluations.isLoading"
                      :disabled="!teachers.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="teachers"
                      item-text="full_name"
                      item-value="id_user"
                      v-model="evaluations.filters.user_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.first_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ evaluations.filters.user_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-autocomplete
                      :label="stages.isLoading ? 'Cargando etapas' : 'Etapa'"
                      :loading="stages.isLoading"
                      :disabled="!stages.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="stages.data"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.stage_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ evaluations.filters.stage_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle class="mt-1">
                            <span>
                              Desde
                              <strong class="ml-1">{{
                                data.item.start_date
                              }}</strong>
                              hasta
                              <strong class="ml-1">{{
                                data.item.end_date
                              }}</strong>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <!-- end::Filters -->
        <!--begin: Datatable-->
        <v-card>
          <v-card-title>
            Listado de perfiles
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="evaluations.headers"
            :items="filteredData"
            :search="evaluations.search"
            :items-per-page="10"
            :loading="evaluations.isLoading"
            show-expand
            single-expand
            :expanded.sync="evaluations.expanded"
          >
            <template v-slot:[`item.evaluation_status`]="{ item }">
              <v-chip
                :color="getEvaluationStatusColor(item.evaluation_status_id)"
              >
                {{ item.evaluation_status }}
              </v-chip>
            </template>
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template
                v-if="existInArray('Revisar perfil', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="reviewEvaluation(item)"
                      color="pink accent-3"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="
                        item.evaluation_status_id == 3 ||
                          item.evaluation_status_id == 5
                      "
                    >
                      mdi-book-check
                    </v-icon>
                  </template>
                  <span>Revisar perfil</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Ver perfil', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="goToEvaluation(item)"
                      color="purple accent-4"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-book-information-variant
                    </v-icon>
                  </template>
                  <span>Ver perfil</span>
                </v-tooltip>
              </template>

              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      @click="
                        selectEvaluation(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Actions -->
            <!-- begin: Expand -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-container>
                  <v-row class="pt-4">
                    <v-col class="pt-0">
                      <div class="black--text mb-1 flex-column">
                        <div class="text-body-2">Descripción</div>
                        <span class="text-body-1">
                          {{ item.description }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <div class="black--text mb-1 flex-column">
                        <div class="text-body-2">Fecha de inicio</div>
                        <span class="text-body-1">
                          {{ item.start_date_formatted }}
                        </span>
                      </div>
                    </v-col>
                    <v-col class="pt-0">
                      <div class="black--text mb-1 flex-column">
                        <div class="text-body-2">Fecha de finalización</div>
                        <span class="text-body-1">
                          {{ item.end_date_formatted }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
            <!-- end: Expand -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <DeleteBaseModal ref="mdlDelete" @delete="deleteEvaluation">
      <template v-slot:title>¿Desea eliminar este perfil?</template>
      <template v-slot:subject>
        el perfil de
        <strong>{{ selectedEvaluation.subject }}</strong>
        de
        <strong>{{ selectedEvaluation.grade }}.</strong>
        <strong>{{ selectedEvaluation.stage }}</strong>
        con ponderación de
        <strong>{{ selectedEvaluation.percent_formatted }}</strong>
        del docente
        <strong>{{ selectedEvaluation.user_full_name }}</strong>
      </template>
    </DeleteBaseModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_USERS } from "@/core/services/store/role.module.js";
import { mapGetters } from "vuex";
import evaluationRepository from "@/repositories/evaluationRepository";
import gradeRepository from "@/repositories/gradeRepository";
import specialityRepository from "@/repositories/specialityRepository";
import evaluationStatusRepository from "@/repositories/evaluationStatusRepository";
import evaluationTrackingRepository from "@/repositories/evaluationTrackingRepository";
import subjectRepository from "@/repositories/subjectRepository";
import activityCategoryRepository from "@/repositories/activityCategoryRepository";
import stageRepository from "@/repositories/stageRepository";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";

export default {
  name: "Evaluations",
  title: "Perfiles | GE ITR",
  components: {
    DeleteBaseModal,
  },
  data() {
    return {
      evaluations: {
        headers: [
          { text: "Tipo de actividad", value: "activity_type" },
          { text: "Asignatura", value: "subject" },
          { text: "Etapa", value: "stage" },
          { text: "Agrupación del perfil", value: "activity_category" },
          { text: "Ponderación (%)", value: "percent_formatted" },
          { text: "Docente", value: "user_full_name" },
          { text: "Estado", value: "evaluation_status" },
          { text: "Grado", value: "grade" },
          { text: "Especialidad", value: "speciality" },
          { text: "Acciones", value: "actions", sortable: false },
          { text: "", value: "data-table-expand" },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
        expanded: [],
      },
      subjects: {
        data: [],
        filters: {},
        isLoading: false,
      },
      selectedEvaluation: {},
      grades: {
        data: [],
        isLoading: false,
      },
      stages: {
        data: [],
        isLoading: false,
      },
      specialities: {
        data: [],
        isLoading: false,
      },
      evaluationStatuses: {
        data: [],
        isLoading: false,
      },
      activityCategories: {
        data: [],
        isLoading: false,
      },
      STORAGE_FILTERS_KEY: "evaluations_filters",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Perfiles", route: "evaluations" },
    ]);
    this.loadEvaluations();
    this.getGrades();
    this.getStages();
    this.getSpecialities();
    this.getEvaluationStatuses();
    this.getSubjects();
    this.getActivityCategories();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Perfiles");
    this.$store.dispatch(FETCH_USERS);

    this.getFiltersFromStorage();
  },
  methods: {
    selectEvaluation(obj) {
      // Creating a non reactive copie of data
      this.selectedEvaluation = { ...obj };
    },
    unselectEvaluation() {
      this.selectedEvaluation = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    loadEvaluations() {
      this.evaluations.isLoading = true;
      evaluationRepository
        .getAllEvaluations()
        .then(({ data }) => {
          this.evaluations.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.evaluations.isLoading = false;
        });
    },
    getGrades() {
      this.grades.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.grades.isLoading = false;
        });
    },
    getStages() {
      this.stages.isLoading = true;
      stageRepository
        .getAllStages()
        .then(({ data }) => {
          this.stages.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.stages.isLoading = false;
        });
    },
    getSpecialities() {
      this.specialities.isLoading = true;
      specialityRepository
        .getAllSpecialities()
        .then(({ data }) => {
          this.specialities.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.specialities.isLoading = false;
        });
    },
    getEvaluationStatuses() {
      this.evaluationStatuses.isLoading = true;
      evaluationStatusRepository
        .getAllEvaluationStatuses()
        .then(({ data }) => {
          this.evaluationStatuses.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.evaluationStatuses.isLoading = false;
        });
    },
    goToEvaluation({ id, subject_id }) {
      this.$router.push({
        path: "evaluation",
        query: {
          e: id,
          s: subject_id,
          f: "evaluations",
        },
      });
    },
    getSubjects() {
      this.subjects.isLoading = true;
      subjectRepository
        .getAllSubjects()
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },
    reviewEvaluation({ id, subject_id }) {
      // Agregando registro a la bitacora de seguimiento del perfil con el id del estado correspondiente a En revisión
      let payload = {
        evaluation_id: id,
        evaluation_status_id: 2,
        user_id: this.currentUserPersonalInfo.id_user,
      };
      evaluationTrackingRepository
        .createEvaluationTracking(payload)
        .then(() => {
          this.goToEvaluation({ id, subject_id });
        });
    },
    getActivityCategories() {
      activityCategoryRepository
        .getAllActivityCategories()
        .then(({ data }) => {
          this.activityCategories.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getEvaluationStatusColor(evaluationStatusId) {
      let color;
      switch (evaluationStatusId) {
        case 1:
        case 4:
          color = "warning";
          break;
        case 2:
          color = "info";
          break;
        case 3:
          color = "error";
          break;
        case 5:
          color = "success";
        default:
          break;
      }
      return color;
    },
    saveFiltersOnStorage() {
      if (Object.keys(this.evaluations.filters).length) {
        const parsedFilterObj = JSON.stringify(this.evaluations.filters);
        sessionStorage.setItem(this.STORAGE_FILTERS_KEY, parsedFilterObj);
      }
    },
    getFiltersFromStorage() {
      const filters = sessionStorage.getItem(this.STORAGE_FILTERS_KEY);
      if (!filters) return;
      this.evaluations.filters = JSON.parse(filters);
    },
    deleteEvaluation() {
      evaluationRepository
        .deleteEvaluation(this.selectedEvaluation.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadEvaluations();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro seleccionado en estos momentos 🙁`,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    filteredData() {
      this.saveFiltersOnStorage();
      return this.filterData(this.evaluations.filters, this.evaluations.data);
    },
    filteredSubjects() {
      return this.filterData(this.evaluations.filters, this.subjects.data);
    },
    teachers() {
      let userTypes = [];
      if (process.env.APP_ENV === "local") {
        userTypes.push("Superadministrador");
        userTypes.push("Superadmin");
      } else {
        userTypes.push("Docente académico");
        userTypes.push("Docente técnico");
      }

      let arrTeachers = this.$store.getters.getUsersByUserType(userTypes);
      let data = arrTeachers.map((item) => {
        return {
          ...item,
          full_name: item.first_name + " " + item.last_name,
        };
      });
      return data;
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
